$(document).ready(function () {
    $(".dropdown").hover(
        function () {
            $('.dropdown-menu', this).not('.in .dropdown-menu').stop(true, true).slideDown("400");
            $(this).toggleClass('open');
        },
        function () {
            $('.dropdown-menu', this).not('.in .dropdown-menu').stop(true, true).slideUp("400");
            $(this).toggleClass('open');
        }
    );

  


    $(".form_datetime").datepicker({});

    $('.select2').select2({tags: true});

    $(document).delegate(".label_roles","click",function(e){

        $id = $(this).attr('data-id');

        if(!$("#roles_" + $id).is(':checked'))
        {
            $(".subroles" + $id).show();

        }else
        {
            $(".subroles" + $id).hide();

            $(".subroles" + $id).find(':checkbox').each(function(){

                jQuery(this).attr('checked', false);

            });
        }




    });

    $(document).delegate(".sub_role_it","click",function(e){
        e.preventDefault();
       var div_id =  'subroles' +$(this).attr("data-id");
$(".sub_roles_container").hide();
        $("." + div_id).show();

    });





});



window.onload = function() {

    $( ".richtext" ).each(function() {

        console.log($(this).attr('id'));
        CKEDITOR.replace( $(this).attr('id'), {
            'filebrowserBrowseUrl': 'http://issmge.dev/filemanager/index.html',
            'language': 'en',
            'removeButtons': 'Source,Save,NewPage,DocProps,Preview,Print,document,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,' +
        'TextField,Textarea,Select,Button,ImageButton,HiddenField,Subscript,BidiLtr,BidiRtl,CreatePlaceholder,Flash,Smiley,Iframe,About,Set language'
    });
});
};




$(document).ready( function() {
    $('#myCarousel').carousel({
        interval:   4000
    });

    var clickEvent = false;
    $('#myCarousel').on('click', '.nav a', function() {
        clickEvent = true;
        $('.nav li').removeClass('active');
        $(this).parent().addClass('active');
    }).on('slid.bs.carousel', function(e) {
        if(!clickEvent) {
            var count = $('.nav').children().length -1;
            var current = $('.nav li.active');
            current.removeClass('active').next().addClass('active');
            var id = parseInt(current.data('slide-to'));
            if(count == id) {
                $('.nav li').first().addClass('active');
            }
        }
        clickEvent = false;
    });
});



