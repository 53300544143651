$(document).ready(function(){


    $(document).delegate(".remove_image","click",function(e){
        $item = $(this);
        e.preventDefault();
        $item.parent().parent().find('input:hidden').attr('name','delete_images[]');
        $item.parent().parent().hide();
    });

$(document).delegate(".deleteme","click",function(e){

    $item = $(this);
        e.preventDefault();

     $token =  $('meta[name="csrf-token"]').attr('content');

    var url  = $(this).attr('href');
    bootbox.confirm("Are you sure?", function(result) {
       if (result)
       {
           console.log($token);
           $.ajax({
               url: url,
               method: "POST",
               headers: {
                   'X-CSRF-TOKEN': $token
               },
               data: { _method: "DELETE", '_token': $token  },
               beforeSend: function( xhr ) {

               }
           })
               .done(function( data ) {
                   if ( console && console.log ) {
                       bootbox.alert("You deleted it!!");

                       $parent =  $item.parent().parent().hide();

                   }
               });


       }

    });

    });


});